export default function synonymReplacement(translations, synonyms) {
  if (!synonyms || synonyms.length === 0) {
    return translations;
  }

  let translationsWithSynonyms = { ...translations };

  let synonymPatterns = [];

  // parse synonyms
  for (let i = 0; i < synonyms.length; i++) {
    const exclusions = synonyms[i].exclusions ? synonyms[i].exclusions : '';
    const suffix = exclusions ? `(?!(${exclusions}))` : '';
    const prefix = exclusions ? `(?<!(${exclusions}))` : '';

    const replacement = synonyms[i].replacement;
    const subject = synonyms[i].subject;

    const pattern = new RegExp(
      prefix + // Negative-lookbehind using a bar separated list of exclusions
        `${subject}` + // The text to match on
        suffix + // Negative-lookahead using a bar separated list of exclusions
        '(?![\\w\\s]*[}])', // Exclude matches wrapped in curly brackets (used for variables in js translations)
      'g', // Global flag. Replace all matches found in the string.
    );

    synonymPatterns.push({
      pattern,
      replacement,
    });
  }

  // replace translations with synonyms
  for (const key of Object.keys(translationsWithSynonyms)) {
    if (typeof translationsWithSynonyms[key] !== 'string') {
      continue;
    }

    for (let i = 0; i < synonymPatterns.length; i++) {
      translationsWithSynonyms[key] = translationsWithSynonyms[key].replace(
        synonymPatterns[i].pattern,
        synonymPatterns[i].replacement,
      );
    }
  }

  return translationsWithSynonyms;
}
