import axios from 'axios';

const {
  initialState,
  token,
  location: { origin },
} = window;

const Client = axios.create();

Client.interceptors.request.use((config) => {
  config.baseURL = config.url.startsWith('/')
    ? origin
    : `${origin}/api/internal/v1`;

  return config;
});

Client.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

if (token) {
  Client.defaults.headers.common['X-CSRF-TOKEN'] = token;
} else {
  console.error('CSRF token not found');
}

if (initialState) {
  Client.defaults.headers.common['Accept-Language'] = initialState.user.locale;
}

export const updateCommonHeaders = (headers = {}) => {
  for (let key in headers) {
    Client.defaults.headers.common[key] = headers[key];
  }
};

export default Client;
