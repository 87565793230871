import { TextInput } from '@coconut-software/ui';
import { parsePhoneNumber } from 'libphonenumber-js/core';
import metadata from 'libphonenumber-js/metadata.max.json';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

function parse(number, country) {
  let phoneNumber;

  try {
    phoneNumber = parsePhoneNumber(number, country, metadata);
  } catch (e) {
    return { number: number.trim(), country, valid: false };
  }

  return {
    country: phoneNumber.country,
    formattedNumber: phoneNumber.formatInternational(),
    number: phoneNumber.number,
    valid: phoneNumber.isValid(),
  };
}

const PhoneNumberInput = ({
  country = 'US',
  error,
  helperText,
  onPhoneChange,
  onValidityChange,
}) => {
  const intl = useIntl();
  const inputRef = useRef(null);

  const handleOnChange = (event) => {
    const rawInput = event.target.value;

    const phone = parse(rawInput, country, metadata);

    if (onValidityChange) {
      onValidityChange(phone.valid);
    }
  };

  const handleOnBlur = (event) => {
    const rawInput = event.target.value;

    const phone = parse(rawInput, country, metadata);

    if (phone.formattedNumber) {
      inputRef.current.value = phone.formattedNumber;
    }

    if (onPhoneChange) {
      onPhoneChange(phone);
    }
  };

  return (
    <TextInput
      error={error}
      helperText={helperText}
      inputMode="tel"
      inputRef={inputRef}
      label={intl.formatMessage({ id: 'Label.phone_number' })}
      name="phone"
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      required
      size="medium"
      type="tel"
      variant="outlined"
    />
  );
};

PhoneNumberInput.propTypes = {
  country: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onPhoneChange: PropTypes.func,
  onValidityChange: PropTypes.func,
};

export default PhoneNumberInput;
