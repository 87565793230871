export default {
    "AdditionalUsers.four_plus": "{first}、{second} 以及其他 {number} 个",
    "AdditionalUsers.plus_more": "其他 {number} 个",
    "AdditionalUsers.three": "{first}，{second}，和 {third}",
    "AdditionalUsers.two": "{first} 和 {second}",
    "AppointmentCancelled.email_message": "我们已向您发送一封确认取消的电子邮件。",
    "AppointmentCancelled.this_title": "此预约已取消",
    "AppointmentCancelled.your_title": "您的预约已取消",
    "AppointmentDetails.appointment_details": "预约详情",
    "AppointmentDetails.appointment_duration": "{start} - {end} ({timezone})",
    "AppointmentDetails.appointment_duration_without_end": "{start} ({timezone})",
    "AppointmentDetails.attendee": "参加者",
    "AppointmentDetails.attendees": "参加者",
    "AppointmentDetails.confirmation_number": "确认号码",
    "AppointmentDetails.date_time": "日期和时间",
    "AppointmentDetails.join_by_phone": "通过电话加入: <a>{number}<\/a>",
    "AppointmentDetails.join_call": "加入通话",
    "AppointmentDetails.join_the_call": "使用提供的链接加入",
    "AppointmentDetails.join_with_this_link": "使用此链接加入",
    "AppointmentDetails.location": "位置",
    "AppointmentDetails.phone_call": "手机通话",
    "AppointmentDetails.phone_number_ending_in": "电话号码尾号: {number}",
    "AppointmentDetails.pin": "PIN 码: {pin}",
    "AppointmentDetails.service": "服务",
    "AppointmentDetails.user": "员工",
    "AppointmentDetails.video_call": "视频通话",
    "AppointmentDetails.we_will_call": "我们将拨打您提供的电话号码",
    "AppointmentDetails.we_will_call_you": "我们将拨打您已提供的电话号码",
    "AppointmentDetails.we_will_send_video_info": "我们将向您发送有关如何参加会议的信息",
    "AppointmentSummary.cancel": "取消",
    "AppointmentSummary.default_error_title": "发生错误",
    "AppointmentSummary.location_button": "查看位置",
    "AppointmentSummary.location_unavailable_description": "此位置不再可用。您需要先选择其他位置才能预约。",
    "AppointmentSummary.location_unavailable_title": "位置不再可用",
    "AppointmentSummary.modal_title": "标题",
    "AppointmentSummary.service_button": "查看服务",
    "AppointmentSummary.service_unavailable_description": "此服务不再可用。请查看是否有任何其他服务满足您的需求或拨打 {phone} 以联系我们寻求帮助。",
    "AppointmentSummary.service_unavailable_title": "服务不再可用",
    "AppointmentSummary.times_button": "寻找合适的时间",
    "AppointmentSummary.times_unavailable_description": "{date} 的 {time} 不再可用。您需要先选择其他时间才能预约。",
    "AppointmentSummary.times_unavailable_title": "时间不再可用",
    "AppointmentSummaryExpansionPanel.title": "预约摘要",
    "AttendeeListItem.delete": "删除其他参加者",
    "AttendeeListItem.deleted": "已删除 {name}",
    "AttendeeListItem.edit": "更新其他参加者",
    "BackButton.back": "后退",
    "BackButton.back_results": "返回结果",
    "BookingValidationErrorModal.default_error_title": "发生错误",
    "BookingValidationErrorModal.location_button": "查看位置",
    "BookingValidationErrorModal.location_unavailable_description": "此位置不再可用。您需要先选择其他位置才能预约。",
    "BookingValidationErrorModal.location_unavailable_title": "位置不再可用",
    "BookingValidationErrorModal.modal_title": "标题",
    "BookingValidationErrorModal.service_button": "查看服务",
    "BookingValidationErrorModal.service_unavailable_description": "此服务不再可用。请查看是否有任何其他服务满足您的需求或拨打 {phone} 以联系我们寻求帮助。",
    "BookingValidationErrorModal.service_unavailable_title": "服务不再可用",
    "BookingValidationErrorModal.times_button": "寻找合适的时间",
    "BookingValidationErrorModal.times_unavailable_description": "{date} 的 {time} 不再可用。您需要先选择其他时间才能预约。",
    "BookingValidationErrorModal.times_unavailable_title": "时间不再可用",
    "CancelModal.additional_details": "其他详情",
    "CancelModal.appointment_date": "{date} 的 {time}",
    "CancelModal.cancel_appointment": "取消预约",
    "CancelModal.keep": "保持",
    "CancelModal.message": "取消您于 {date} 的预约这一操作无法撤消。",
    "CancelModal.title": "取消预约",
    "CancelModal.why_cancel_appointment": "为什么这个预约被取消了?",
    "ChangesToYourAppointment.cancellation_no_longer_available": "此预约不能再取消。",
    "ChangesToYourAppointment.cancellation_policy_header": "取消政策",
    "ChangesToYourAppointment.cannot_cancel": "此预约不能取消。",
    "ChangesToYourAppointment.cannot_reschedule": "此预约不能重新安排。",
    "ChangesToYourAppointment.loading": "正在加载…",
    "ChangesToYourAppointment.meeting_method_subtitle": "如果您想更改履行预约的方式，请更新会议方式。",
    "ChangesToYourAppointment.meeting_method_subtitle_limited": "如果您想更改履行预约的方式，您最多可以在预定的时间前 {duration} 进行更改。",
    "ChangesToYourAppointment.meeting_method_subtitle_locked": "会议方式无法更改",
    "ChangesToYourAppointment.no_meeting_method_options": "没有其他可用选项",
    "ChangesToYourAppointment.reschedule_appointment": "预约改期",
    "ChangesToYourAppointment.reschedule_header": "改期",
    "ChangesToYourAppointment.reschedule_no_longer_available": "此预约不能再改期。",
    "ChangesToYourAppointment.reschedule_policy_details": "如果您需要将预约改期，您最多可以在预定的时间前 {duration} 进行更改。",
    "ChangesToYourAppointment.reschedule_policy_details_no_limit": "如果您需要将预约改期，您可以在预定的时间前进行更改。",
    "ChangesToYourAppointment.title": "预约更改",
    "ChangesToYourAppointment.update_meeting_method": "更新会议方式",
    "ChangesToYourAppointment.update_meeting_method_not_available": "此预约的会议方式不能再更改。",
    "CheckIn.check_in": "报到",
    "CheckIn.check_in.aria_label": "检查你的约会",
    "CheckIn.confirmation.subtitle": "我们稍后将为您服务。",
    "CheckIn.confirmation.subtitle_notifiable": "我们稍后将为您服务。轮到您时，我们会通知您。",
    "CheckIn.confirmation.title": "您已签到!",
    "CheckIn.email": "电子邮件地址",
    "CheckIn.fetch_failed": "很抱歉，搜索您的约会时出错。请刷新页面并重试。",
    "CheckIn.join_line": "加入队伍",
    "CheckIn.join_line.aria_label": "单击此处加入要查看的行而不是预约",
    "CheckIn.join_line.title": "没有预约？",
    "CheckIn.landing_page.title": "查看您的约会",
    "CheckIn.next": "下一个",
    "CheckIn.next.aria_label": "查看您的约会",
    "CheckIn.not_found.email": "很抱歉，我们找不到与您输入的电子邮件地址相匹配的近期预约。请注意，预约只能在预定开始时间前 30 分钟内办理。",
    "CheckIn.not_found.phone": "很抱歉，我们找不到与您输入的电话号码相匹配的近期预约。请注意，预约只能在预定开始时间前 30 分钟内办理。",
    "CheckIn.phone": "电话号码",
    "CheckIn.preference": "使用您的{preference}查找您的约会",
    "CheckIn.subtitle": "使用您的电子邮件地址或电话号码查找您的约会",
    "CheckIn.subtitle_email_only": "使用您的电子邮件地址查找您的约会",
    "CheckIn.summary.fetch_failed": "很抱歉，检查您的预约时发生错误。请刷新页面并重试。",
    "CheckIn.summary.sms_updates": "接收短信更新",
    "CheckIn.summary.sms_updates.dialog": "收到短信更新吗？",
    "CheckIn.summary.sms_updates.dialog.accept": "是的，通过短信发我更新信息",
    "CheckIn.summary.sms_updates.dialog.deny": "不，签到但不更新",
    "CheckIn.summary.sms_updates.message": "我们会向您发送有关何时轮到{phone}更新信息",
    "CheckIn.summary.subtitle": "在下方确认您的预约详情并签到",
    "CheckIn.summary.title": "确认并签到",
    "CheckIn.title": "查看您的约会",
    "CircularProgress.loading": "正在加载",
    "Common.appointment_details": "预约详情",
    "CookieNotice.accept": "我接受",
    "CookieNotice.closed": "Cookie 免责声明已关闭。",
    "CookieNotice.ignore": "忽略",
    "CookieNotice.learn_more": "了解更多",
    "CookieNotice.message": "我们使用 Cookie 来确保您在我们的网站上获得最佳体验。",
    "CookieNotice.title": "Cookie 免责声明",
    "CountrySelectInput.label": "国家\/地区",
    "CountrySelectInput.most_popular": "最受欢迎",
    "CountryTimezoneModal.description": "将根据下方选定的时区显示所有时间。",
    "CountryTimezoneModal.title": "选择时区",
    "DateTime.locale_badge_label": "{name} 讲 {language}",
    "DateTime.locale_badge_tooltip": "讲 {language}",
    "DateTime.mobile_header": "您想在什么时候预约?",
    "DateTime.no_language_preference": " (没有 {locale})",
    "DateTime.no_times_available_on_date": "没有可用时间",
    "DateTime.select_time": "选择时间",
    "DateTime.select_user": "选择一名员工",
    "DateTime.showing_times_for": "显示该时区的可用时间：",
    "DateTime.times_available_for_people_speaking": "在 {date}，关于 {locale} 的可用时间",
    "DateTime.times_available_on_date": "{date} 的可用时间",
    "DateTime.timezone_header": "时间以 {timezone} 显示",
    "DateTime.user_mobile_header": "您想在什么时候与 {name} 会面?",
    "DateTime.user_schedule": "与 {name} 安排会面",
    "DateTime.who_speaks": " 讲 {locale} 的人员",
    "Dates.all_day_date": "{date}，整天",
    "Dates.all_day_today": "今天，整天",
    "Dates.plural.day": "{count, plural, one {天} other {天}}",
    "Dates.plural.month": "{count, plural, one {月} other {月}}",
    "Dates.plural.week": "{count, plural, one {周} other {周}}",
    "Dates.plural.year": "{count, plural, one {年} other {年}}",
    "Dates.range_today": "今天，{start} - {end}",
    "Dates.range_today_with_timezone": "今天，{start} - {end}{timezone}",
    "Dates.today_with_timezone": "今天，{date} {timezone}",
    "DesktopUsers.locale_badge_tooltip": "讲 {language}",
    "Details.form_header": "输入您的详细信息",
    "DetailsForm.add": "添加",
    "DetailsForm.add_attendee": "+ 添加其他参加者",
    "DetailsForm.add_attendee_title": "添加参加者",
    "DetailsForm.additional_attendees": "其他参加者",
    "DetailsForm.additional_attendees_limit": "(上限 {limit})",
    "DetailsForm.address": "地址",
    "DetailsForm.agree_tos": "预约即表示您同意 Coconut Software 的 {terms} 和 {privacy}。",
    "DetailsForm.agree_tos_with_privacy": "预约即表示您同意 Coconut Software 的 {terms} 和 {privacy}，以及 {vendor} 的 {vendorPrivacy}。",
    "DetailsForm.agree_tos_with_tos": "预约即表示您同意 Coconut Software 的 {terms} 和 {privacy}，以及 {vendor} 的 {vendorTerms}。",
    "DetailsForm.agree_tos_with_vendor": "预约即表示您同意 Coconut Software 的 {terms} 和 {privacy}，以及 {vendor} 的 {vendorTerms} 和 {vendorPrivacy}。",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "预约即表示您同意 {vendor} 的 {vendorTerms}和 {vendorPrivacy}。",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "预约即表示您同意 {vendor} 的 {vendorPrivacy}。",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "预约即表示您同意 {vendor} 的 {vendorTerms}。",
    "DetailsForm.appointment_policy_action": "预约",
    "DetailsForm.cell_phone": "手机号码",
    "DetailsForm.cell_phone_context": "我们会拨打您的这个号码",
    "DetailsForm.city": "城市",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.country": "国家\/地区",
    "DetailsForm.duplicate_attendee": "已存在具有此姓名和电子邮件地址的参加者",
    "DetailsForm.edit": "保存",
    "DetailsForm.edit_attendee_title": "编辑参加者",
    "DetailsForm.email": "电子邮件",
    "DetailsForm.explicit_compliance_agreement_label": "我接受",
    "DetailsForm.first_name": "名字",
    "DetailsForm.home_phone": "家庭电话号码",
    "DetailsForm.last_name": "姓氏",
    "DetailsForm.notes": "预约详情",
    "DetailsForm.postal_code": "邮政编码",
    "DetailsForm.primary_phone_context": "我们会拨打您的这个号码",
    "DetailsForm.privacy": "隐私声明",
    "DetailsForm.province": "省份",
    "DetailsForm.receive_sms": "通过短信接收预约通知",
    "DetailsForm.receive_sms_required": "我们将使用此信息向您发送关于预约的重要更新",
    "DetailsForm.select_country": "请选择国家\/地区",
    "DetailsForm.select_province": "请选择省份",
    "DetailsForm.select_state": "请选择州",
    "DetailsForm.sms_error_title": "重新选择接收短信。",
    "DetailsForm.state": "州",
    "DetailsForm.submit": "继续",
    "DetailsForm.terms_of_use": "使用条款",
    "DetailsForm.walkin_policy_action": "加入行",
    "DetailsForm.work_phone": "工作电话号码",
    "Dialog.clear": "清除",
    "Dialog.confirm": "确认",
    "Dropzone.delete": "删除",
    "Dropzone.instructions": "将文件拖到此处或<span>浏览文件以上传<\/span>",
    "Duration.days": "{duration, plural, one {# 天} other {＃ 天}}",
    "Duration.hours": "{duration, plural, one {# 小时} other {＃ 小时}}",
    "Duration.minutes": "{duration, plural, one {# 分钟} other {＃ 分钟}}",
    "Duration.weeks": "{duration, plural, one {# 周} other {# 周}}",
    "EmptyState.contact_vendor": "请重试或联系我们",
    "FastRoute.book_anyway": "改为预约",
    "FastRoute.skip_the_wait": "跳过等待",
    "FastRoute.title": "无需预约",
    "Feedback.already_submitted": "反馈已提交，谢谢！",
    "Feedback.appointment_review_subtitle": "请告诉我们您对这次约会的感受。",
    "Feedback.appointment_review_title": "预约反馈",
    "Feedback.close": "关闭反馈部分",
    "Feedback.error": "请在提交前进行评分",
    "Feedback.experience": "您的体验",
    "Feedback.experience_error_message": "反馈是必填字段。请确保长度大于144个字符",
    "Feedback.experience_required": "您的经验（必填）",
    "Feedback.google_description": "我们非常重视您的反馈，我们相信这有助于其他客户进行选择。如果您有时间通过 Google 评价进行反馈以向其他客户分享您的体验，请点击下方按钮以重定向至相应网站。",
    "Feedback.google_submit": "提交 Google 评价",
    "Feedback.neutral": "中立",
    "Feedback.new_title": "反馈",
    "Feedback.rating": "评分 (必填)",
    "Feedback.response_prompt": "告诉我们该如何改进体验",
    "Feedback.send": "发送反馈",
    "Feedback.sending": "发送反馈",
    "Feedback.staff_review_subtitle": "请告诉我们我们的员工今天表现如何。",
    "Feedback.staff_review_title": "员工反馈",
    "Feedback.subtitle": "您的反馈是匿名的。我们将使用这些信息来改进预订体验。",
    "Feedback.success": "已成功发送反馈，谢谢!",
    "Feedback.thank_you": "感谢您的反馈!",
    "Feedback.title": "您对此次预约的感受如何?",
    "Feedback.very_dissatisfied": "非常不满",
    "Feedback.very_satisfied": "非常满意",
    "FetchSlots.error": "出了点问题，请稍后重试",
    "FindAvailableDate.earliest_available": "最早可用",
    "FindAvailableDate.finding_first_time": "查找第一个可用时间",
    "FindAvailableDate.finding_next_time": "查找下一个可用时间",
    "FindAvailableDate.finding_time": "寻找可用时间",
    "FindAvailableDate.first_available_date": "尽快地",
    "FindAvailableDate.next_available": "下一个可用",
    "FindAvailableDate.next_available_date": "下一个可用日期",
    "FindAvailableDate.tooltip_error": "获取日期时出错。",
    "FindAvailableDate.tooltip_fetching": "正在获取日期...",
    "Form.field_error.cell_phone_characters": "请输入有效的手机号码。",
    "Form.field_error.cell_phone_length": "请输入长度在 9-20 个字符之间的有效手机号码。",
    "Form.field_error.email_structure": "请输入有效的电子邮件地址。",
    "Form.field_error_max_length": "此字段不能超过{max}个字符。",
    "Form.field_error_min_length": "此字段不能少于{min}个字符。",
    "Form.field_required": "此字段是必需的。",
    "Form.optional": "(选填)",
    "GenericError.subtitle": "找不到您要查找的页面",
    "GenericError.title": "哎呀，发生错误了",
    "Input.additional_input": "其他信息",
    "Invitations.verification_failed": "您提供的电子邮件地址与我们的文件不匹配。",
    "Label.phone_number": "电话号码",
    "LandingChoices.book_appointment_subtitle": "选择一个特定的日期和时间以拜访",
    "LandingChoices.book_appointment_title": "预约",
    "LandingChoices.callback_subtitle": "今日尽快拜访",
    "LandingChoices.callback_title": "加入等待",
    "LandingChoices.check_in_subtitle": "如果您要提前预约",
    "LandingChoices.check_in_title": "签到",
    "LandingChoices.choose_another_location_subtitle": "单击此处搜索其他位置",
    "LandingChoices.prefill_title": "欢迎使用 {name}!您想做什么{space}?",
    "LandingChoices.prefill_title.service": "欢迎使用 {name}!您想如何进行 {service} 预约?",
    "LandingChoices.private_location": "该位置是私人的",
    "LandingChoices.title": "您想做什么{space}?",
    "LandingChoices.title.service": "您想如何进行 {service} 预约?",
    "LandingChoices.virtual_location": "虚拟位置",
    "LandingChoices.welcome_to": "欢迎使用",
    "Languages.chinese": "中国人",
    "Languages.english": "英语",
    "Languages.french": "法语",
    "Languages.korean": "韩国人",
    "Languages.language": "语言",
    "Languages.polish": "波兰语",
    "Languages.portuguese": "葡萄牙语",
    "Languages.russian": "俄语",
    "Languages.spanish": "西班牙语",
    "LobbyBanner.button": "加入等待",
    "LobbyBanner.button_description": "单击此处加入要查看的行而不是预约",
    "LobbyBanner.phone_button": "请求回叫",
    "LobbyBanner.phone_button_description": "单击此处预订电话回拨而不是预约",
    "LobbyBanner.title": "无需预约!",
    "LobbyBanner.wait_time": "预计等待时间: {time} 分钟",
    "Location.previously_booked": "之前已预订",
    "Location.search_area": "搜索此区域",
    "Location.select_location": "选择一个位置",
    "Location.select_location_prefill": "{name}，选择位置",
    "LocationDetails.continue": "继续",
    "LocationDetails.location_details": "位置详情",
    "LocationDetails.see_all_locations": "查看所有位置",
    "LocationDetails.select_location": "选择此位置",
    "LocationDirectionsButton.text": "路线",
    "LocationHours.closed": "已关闭",
    "LocationHours.title": "营业时长",
    "LocationIdentifier.empty_error": "请输入位置编号",
    "LocationIdentifier.help_text": "首先输入您在标志上看到的位置编号",
    "LocationIdentifier.location_number": "位置编号",
    "LocationIdentifier.not_found_error": "此编号不与我们的任何位置匹配",
    "LocationIdentifier.title": "欢迎!",
    "LocationList.empty_state_message": "未找到结果",
    "LocationList.empty_state_secondary": "尝试扩大搜索范围",
    "LocationList.finding_locations": "我们正在寻找您附近的位置",
    "LocationList.near_me": "我附近的地点",
    "LocationList.no_locations": "未找到位置",
    "LocationList.title": "选择一个位置",
    "LocationList.toggle_list": "显示列表",
    "LocationList.toggle_map": "显示地图",
    "LocationPhoneCallButton.text": "呼叫 {number}",
    "LocationStepTitle.preferred_location": "您的首选位置是什么?",
    "LocationStepTitle.preferred_location_helper": "我们将为您匹配您所选位置的员工。",
    "LoginWithGoogle.showing_times_that_match": "显示与您的空闲情况相匹配的时间",
    "LoginWithGoogle.sign_in": "通过 Google 登录",
    "LoginWithGoogle.sign_in_details": "显示与您的空闲情况相匹配的时间",
    "Manage.appointment_details": "预约详情",
    "Manage.book_another": "另行预约",
    "Manage.can_never_cancel": "无法在线管理。请联系我们以更改。",
    "Manage.cancel": "取消预约",
    "Manage.cancellation_policy": "取消政策",
    "Manage.cancellation_policy_details": "如果您不能履行预约，请至少在预定的时间前 {duration} 取消预约。",
    "Manage.cancellation_policy_details_no_limit": "如果您不能参加预约，请在预定的时间之前取消预约。",
    "Manage.cancelled": "预约取消",
    "Manage.cancelled_successfully": "预约已取消",
    "Manage.cannot_cancel_anymore": "目前无法在线管理。请联系我们以更改。",
    "Manage.confirmation": "确认",
    "Manage.confirmation_email": "我们已向您发送了一封电子邮件，其中包含您的预约详情，以及与您的预约相关的任何其他信息。",
    "Manage.details": "详情",
    "Manage.in_the_past": "预约已过时",
    "Manage.reschedule": "改期",
    "Manage.restart": "从主页重启",
    "Manage.successfully_booked": "已成功预约",
    "Manage.updated_successfully": "预约已更新",
    "ManageAppointmentDetails.details_below": "您的预约详情如下。我们已通过电子邮件将副本发送给您。",
    "ManageAppointmentDetails.done_title": "完成!",
    "ManageAppointmentDetails.successfully_booked": "您已预订。",
    "ManageAppointmentDetails.successfully_rescheduled": "您的预约已改期。",
    "ManageAppointmentDetails.your_appointment": "您的预约",
    "MeetingMethodForm.appointment_date": "{date} 的 {time}",
    "MeetingMethodForm.keep": "保持",
    "MeetingMethodForm.message.in_person": "将 {date} 的预约的会议方式更新为线下会面。",
    "MeetingMethodForm.message.off_site": "将 {date} 的预约的会议方式更新为在您希望的位置会面。",
    "MeetingMethodForm.message.phone_call": "将 {date} 的预约的会议方式更新为手机通话。",
    "MeetingMethodForm.message.video_call": "将 {date} 的预约的会议方式更新为视频通话。",
    "MeetingMethodForm.new_method_label": "您将如何参加预约的会议?",
    "MeetingMethodForm.title": "更新会议方式",
    "MeetingMethodForm.update_method": "更新会议方式",
    "MeetingMethodInfoBanner.only_phone_available": "此会议只能通过手机通话进行",
    "MeetingMethodInfoBanner.only_video_available": "此会议只能通过视频通话进行",
    "MeetingMethods.at_location_primary": "亲自",
    "MeetingMethods.at_location_secondary": "选择一个位置",
    "MeetingMethods.header": "您将如何参加预约的会议?",
    "MeetingMethods.off_site_primary": "异地",
    "MeetingMethods.off_site_secondary": "我们将与您会面",
    "MeetingMethods.phone_call_primary": "电话",
    "MeetingMethods.phone_call_secondary": "我们将与您通话",
    "MeetingMethods.video_call_primary": "视频",
    "MeetingMethods.video_call_secondary": "我们将向您发送一个链接",
    "MonthlyDatePicker.next_month": "下个月",
    "MonthlyDatePicker.previous_month": "前一个月",
    "Navigation.aria_label.back": "返回上一步",
    "Navigation.aria_label.call": "通话位置",
    "Navigation.aria_label.card": "选择 {navItem}",
    "Navigation.aria_label.date": "选择 {date}",
    "Navigation.aria_label.directions": "查看路线",
    "Navigation.aria_label.language": "讲 {language} 的员工",
    "Navigation.aria_label.language_specific": "特定员工",
    "NoLocationPreferenceButton.skip": "跳过",
    "NoLocationPreferenceButton.title": "我没有首选项",
    "NoTimesAvailable.no_times_in_month": "{month} 没时间",
    "NoTimesAvailable.no_times_in_week": "本周没时间",
    "NoTimesAvailable.view_next_month": "查看下月情况",
    "NoTimesAvailable.view_next_week": "查看下周情况",
    "NotAvailable.subtitle": "我们目前暂不接受预约",
    "NotAvailable.title": "不可用",
    "OtherLocationsTimeChunks.available_times_title": "其他位置的可用时间",
    "OtherLocationsTimeChunks.no_available_times": "其他位置没有可用时间",
    "OtherLocationsTimeChunks.view_available_times": "查看其他位置的可用时间 ",
    "OtherLocationsTimeChunks.view_more": "查看更多",
    "Pages.country": "国家\/地区",
    "Pages.staff_preference": "员工首选项",
    "Pages.supported_language_preference": "语言",
    "Pages.timezones": "时区",
    "PositionErrorAlert.denied": "我们没有获得您的许可，因此无法获取您的位置。检查此站点的浏览器设置。",
    "PositionErrorAlert.retry": "重试",
    "PositionErrorAlert.unable": "我们无法获取您的位置。",
    "RadioButton": "单选按钮",
    "Reschedule.appointment_details": "预约详情",
    "Reschedule.confirm_time": "确认时间",
    "Reschedule.error": "预约不能改期",
    "Reschedule.label": "改期确认",
    "Reschedule.reschedule": "改期",
    "Security.continue": "继续",
    "Security.email_verification": "电子邮件验证",
    "Security.please_verify": "请在预约前验证您的电子邮件地址。",
    "SelectInput.default": "选择",
    "Service.all_categories_title": "所有类别",
    "Service.category_title": "类别",
    "Service.five_minutes": "< 5 minutes",
    "Service.hours": "{hours, plural, one {# 小时} other {＃ 小时}}",
    "Service.minutes": "{minutes, plural, one {# 分钟} other {# 分钟}}",
    "Service.mobile_header": "您在哪方面需要帮助?",
    "Service.no_services": "未找到服务",
    "Service.read_less": "大致了解",
    "Service.read_more": "详细了解",
    "Service.select_title": "选择一项服务",
    "Service.select_title_prefill": "{name}，{break}选择一项服务",
    "Service.service_count": "{number, plural, one {# 项服务} other {＃ 项服务}}",
    "Service.uncategorized_title": "其他",
    "ServiceHeader.group": "群组",
    "ServiceInstructions.title": "说明",
    "ShortcutExpired.message": "此链接已过期",
    "Sidebar.footer": "技术支持者",
    "Sidebar.title": "预约",
    "Sidebar.title_check_in": "报到",
    "Sidebar.title_join_the_line": "加入行",
    "SpecificUserLanguagePreferences.title": "语言首选项",
    "StepCounter.location": "{location}",
    "StepCounter.location_address": "{address}",
    "StepCounter.location_name": "{name}",
    "StepCounter.title": "第 {current} 步，共 {end} 步",
    "Steps.check_in": "报到",
    "Steps.check_in_description": "<p>签到您的约会。 <\/p> <p>当工作人员准备好为您服务时，我们会通知您。 <\/p>",
    "Steps.date_time": "日期和时间",
    "Steps.date_time_no_wait_times": "一旦工作人员准备好为您服务",
    "Steps.date_time_with_wait_times": "预计等待时间： {time}分钟",
    "Steps.date_time_with_zero_wait_times": "暂时没有等待时间",
    "Steps.details": "详情",
    "Steps.details_description": "<p>今天添加您自己。 <\/p> <p>当工作人员准备好为您服务时，我们会通知您回来。 <\/p>",
    "Steps.location": "位置",
    "Steps.meeting_details": "会议详情",
    "Steps.meeting_methods": "会议方式",
    "Steps.no_staff_warning": "通过加入该行，您将得到第一位可用的工作人员的服务。这可能不是您之前选择的{name}",
    "Steps.service": "服务",
    "Steps.services": "服务",
    "Steps.staff": "员工",
    "Steps.staff_preference": "员工首选项",
    "Steps.summary": "摘要",
    "Steps.your_details": "您的详细信息",
    "Summary.book_appointment": "预约",
    "Summary.header": "即将完成!评论和预订",
    "Summary.join_the_line": "加入行",
    "Summary.please_review": "请查阅",
    "SummaryExpansionPanel.title": "概括",
    "SupportedLanguagePreferencePicker.anyone": "任何人",
    "SupportedLanguagePreferencePicker.anyone_available": "任何可用的人",
    "SupportedLanguagePreferencePicker.assigned_staff": "您指派的员工",
    "SupportedLanguagePreferencePicker.language_preference": "语言首选项",
    "SupportedLanguagePreferencePicker.specific_language": "任何讲 {language} 的人",
    "Svg.alt_text.appointment": "预约",
    "Svg.alt_text.back": "后退",
    "Svg.alt_text.back_chevron": "后退",
    "Svg.alt_text.checkbox_not_selected": "未选中复选框",
    "Svg.alt_text.checkbox_selected": "选中的复选框项目",
    "Svg.alt_text.checkmark": "选中标记",
    "Svg.alt_text.clock": "时钟",
    "Svg.alt_text.coconut_logo": "Coconut 软件标志",
    "Svg.alt_text.completed": "完全的",
    "Svg.alt_text.continue": "继续",
    "Svg.alt_text.country_selected": "选定的国家\/地区",
    "Svg.alt_text.down_chevron": "显示更多",
    "Svg.alt_text.drop_down_arrow": "下拉箭头",
    "Svg.alt_text.edit": "编辑",
    "Svg.alt_text.edit_timezone": "更改时区",
    "Svg.alt_text.expand": "展开",
    "Svg.alt_text.expanded": "已展开",
    "Svg.alt_text.forward_chevron": "转发",
    "Svg.alt_text.hide_location_details": "隐藏位置详情",
    "Svg.alt_text.in_person": "位置",
    "Svg.alt_text.item_selected": "选定的项目",
    "Svg.alt_text.language": "语言",
    "Svg.alt_text.location_pin": "位置",
    "Svg.alt_text.near_me": "我附近",
    "Svg.alt_text.next_month": "下个月",
    "Svg.alt_text.next_week": "下周",
    "Svg.alt_text.person": "人",
    "Svg.alt_text.phone": "电话",
    "Svg.alt_text.previous_month": "前一个月",
    "Svg.alt_text.previous_week": "上周",
    "Svg.alt_text.search": "搜索",
    "Svg.alt_text.select_category": "选择类别",
    "Svg.alt_text.select_language": "选择语言",
    "Svg.alt_text.show_location_details": "显示位置详情",
    "Svg.alt_text.timezone": "时区",
    "Svg.alt_text.timezone_selected": "选定的时区",
    "Svg.alt_text.up_chevron": "显示较少",
    "Svg.alt_text.upload": "上传",
    "Svg.alt_text.user_selected": "选定的用户",
    "Svg.alt_text.video": "视频",
    "Svg.alt_text.walk_in": "免预约服务图标",
    "TimeChunk.no_spots": "没有剩余的名额",
    "TimeChunk.spots_remaining": "{number, plural, one {# 个剩余名额} other {# 个剩余名额}}",
    "TimeChunk.unlimited_spots": "无限名额",
    "TimeChunks.afternoon": "下午",
    "TimeChunks.evening": "晚上",
    "TimeChunks.morning": "上午",
    "TimeChunks.no_available_times": "没有可用时间",
    "TimeChunks.no_available_times_in_month": "本月未找到空房。",
    "TimeChunks.no_available_times_in_range": "在{range} {rangeUnit}时间内未找到可用情况。",
    "TimeChunks.no_available_times_in_range_month": "在{range}个月内未找到可用情况。",
    "TimeChunks.no_available_times_in_range_week": "在{range}周内未发现可用情况。",
    "TimeChunks.no_available_times_in_week": "本周未发现可用情况。",
    "TimeChunks.no_available_times_on": "{date} 没有可用时间",
    "TimeChunks.select_another_day": "选择其他日期以查找可用时间",
    "TimeChunks.select_another_month": "再尝试一个月.",
    "TimeChunks.select_another_range": "尝试其他工作人员或地点.",
    "TimeChunks.select_another_week": "再尝试一周.",
    "TimeChunks.select_user": "选择员工以查看他们的空闲情况",
    "TimezoneSelectInput.label": "时区区域",
    "TimezoneSelectInput.required": "时区为必填项。",
    "TimezonesShownIn.label": "显示该时区的可用时间：",
    "TooLateToCancelModal.message": "您无法继续在线取消此预约。",
    "TooLateToCancelModal.title": "来不及取消",
    "Ui.archive": "存档",
    "Ui.back": "后退",
    "Ui.back_to_previous": "返回: {previous}",
    "Ui.cancel": "取消",
    "Ui.clear": "清除",
    "Ui.close": "关闭",
    "Ui.confirm": "确认",
    "Ui.continue": "继续",
    "Ui.current_selection": "当前选择",
    "Ui.details_about_name": "关于 {name} 的详情",
    "Ui.details_about_name_and_address": "关于 {name} (地址为 {address}) 的详情",
    "Ui.dismiss": "取消",
    "Ui.do_not_continue": "继续",
    "Ui.hours_of_operation": "营业时间",
    "Ui.near_me": "我附近",
    "Ui.next": "下一个",
    "Ui.ok": "确定",
    "Ui.search": "搜索",
    "Ui.search.instructions": "请开始输入",
    "Ui.search.no_results_found": "未找到结果",
    "Ui.search.plain": "搜索",
    "Ui.select": "选择",
    "Ui.select_name": "选择 {name}",
    "Ui.selected": "已选择",
    "Ui.sign_out": "退出",
    "Ui.type_here": "在此输入…",
    "Ui.undo": "撤消",
    "UserPreference.anyone": "任何人",
    "UserPreference.anyone_available": "任何可用的人",
    "UserPreference.assign_me_short_title": "{language}",
    "UserPreference.assign_me_subtitle": "只给我指派讲 {language} 的员工",
    "UserPreference.assign_me_title": "任何讲 {language} 的员工",
    "UserPreference.assigned_title": "您指派的员工",
    "UserPreference.header": "您有首选的员工吗?",
    "UserPreference.language_title": "{language}",
    "UserPreference.no_preference": "没有首选项",
    "UserPreference.random_subtitle": "我没有首选项",
    "UserPreference.random_title": "任何可用的人",
    "UserPreference.see_all_staff": "查看所有员工",
    "UserPreference.specific_subtitle": "我有中意的会面对象",
    "UserPreference.specific_title": "特定员工",
    "Validator.address": "地址",
    "Validator.cell_phone": "手机号码",
    "Validator.city": "城市",
    "Validator.country": "国家\/地区",
    "Validator.email": "电子邮件",
    "Validator.first_name": "名字",
    "Validator.home_phone": "家庭电话",
    "Validator.last_name": "姓氏",
    "Validator.meeting_method": "会议方式",
    "Validator.not_url": "不能包含 URL。",
    "Validator.notes": "注释",
    "Validator.phone_code": "国家代码不正确",
    "Validator.phone_country_code": "请提供一个 1-3 位数的国家\/地区代码，前面加上“+”，然后是您的电话号码。",
    "Validator.phone_length": "电话长度不正确",
    "Validator.phone_valid": "请提供有效的电话号码。",
    "Validator.postal_code": "邮政编码",
    "Validator.province": "省",
    "Validator.state": "州",
    "Validator.work_phone": "工作电话",
    "Validator.zip_code": "邮政编码",
    "ViewOnMapButton.title": "在地图上查看",
    "WaitTime.loading": "正在查询免预约服务等候时间…",
    "WaitTime.no_walkins_currently": "目前不接受免预约服务",
    "WaitTime.wait": "当前免预约服务等候时间: {wait}",
    "WaitTime.wait_time": "当前免预约服务等候时间: {waitTime}",
    "WeeklyDatePicker.next_week": "下周",
    "WeeklyDatePicker.previous_week": "上周",
    "WhatNow.homepage": "前往 {vendor} 网站",
    "WhatNow.title": "下一步该做什么?"
}