export default {
    "AdditionalUsers.four_plus": "{first} , {second} et {number} autres",
    "AdditionalUsers.plus_more": "+ {number} plus",
    "AdditionalUsers.three": "{first} , {second} et {third}",
    "AdditionalUsers.two": "{first} et {second}",
    "AppointmentCancelled.email_message": "Nous vous avons envoyé un courriel confirmant votre annulation.",
    "AppointmentCancelled.this_title": "Ce rendez-vous a été annulé",
    "AppointmentCancelled.your_title": "Votre rendez-vous a été annulé",
    "AppointmentDetails.appointment_details": "Détails du rendez-vous",
    "AppointmentDetails.appointment_duration": "{start} - {end} ({timezone})",
    "AppointmentDetails.appointment_duration_without_end": "{start} ({timezone})",
    "AppointmentDetails.attendee": "Participant",
    "AppointmentDetails.attendees": "Participants",
    "AppointmentDetails.confirmation_number": "Numéro de confirmation",
    "AppointmentDetails.date_time": "Date et heure",
    "AppointmentDetails.join_by_phone": "Rejoindre par téléphone : <a> {number} <\/a>",
    "AppointmentDetails.join_call": "Rejoindre l'appel",
    "AppointmentDetails.join_the_call": "Rejoignez-nous avec le lien fourni",
    "AppointmentDetails.join_with_this_link": "Rejoignez-nous en utilisant ce lien",
    "AppointmentDetails.location": "Emplacement",
    "AppointmentDetails.phone_call": "Appel téléphonique",
    "AppointmentDetails.phone_number_ending_in": "Numéro de téléphone se terminant par {number}",
    "AppointmentDetails.pin": "NIP : {pin}",
    "AppointmentDetails.service": "Service",
    "AppointmentDetails.user": "Membre du personnel",
    "AppointmentDetails.video_call": "Appel vidéo",
    "AppointmentDetails.we_will_call": "Nous vous appellerons au numéro que vous fournissez",
    "AppointmentDetails.we_will_call_you": "Nous vous appellerons au numéro que vous avez indiqué",
    "AppointmentDetails.we_will_send_video_info": "Nous vous enverrons des informations sur la façon de participer à la réunion",
    "AppointmentSummary.cancel": "Annuler",
    "AppointmentSummary.default_error_title": "Une erreur s'est produite",
    "AppointmentSummary.location_button": "Voir les emplacements",
    "AppointmentSummary.location_unavailable_description": "Cet emplacement n'est plus disponible. Vous devrez choisir un autre emplacement avant de pouvoir prendre rendez-vous.",
    "AppointmentSummary.location_unavailable_title": "L’emplacement n’est plus disponible",
    "AppointmentSummary.modal_title": "Titre",
    "AppointmentSummary.service_button": "Voir les services",
    "AppointmentSummary.service_unavailable_description": "Ce service n’est plus disponible. Veuillez vérifier si d’autres services répondent à vos besoins ou nous contacter au {phone}.",
    "AppointmentSummary.service_unavailable_title": "Ce service n’est plus disponible",
    "AppointmentSummary.times_button": "Trouver une heure",
    "AppointmentSummary.times_unavailable_description": "{time} n’est plus disponible le {date}. Vous devrez choisir une autre heure avant de pouvoir prendre rendez-vous.",
    "AppointmentSummary.times_unavailable_title": "L’heure n’est plus disponible",
    "AppointmentSummaryExpansionPanel.title": "Résumé du rendez-vous",
    "AttendeeListItem.delete": "Supprimer le participant supplémentaire",
    "AttendeeListItem.deleted": "{name} supprimé",
    "AttendeeListItem.edit": "Mettre à jour le participant supplémentaire",
    "BackButton.back": "Retour",
    "BackButton.back_results": "Retour aux résultats",
    "BookingValidationErrorModal.default_error_title": "Une erreur s'est produite",
    "BookingValidationErrorModal.location_button": "Voir les emplacements",
    "BookingValidationErrorModal.location_unavailable_description": "Cet emplacement n'est plus disponible. Vous devrez choisir un autre emplacement avant de pouvoir prendre rendez-vous.",
    "BookingValidationErrorModal.location_unavailable_title": "L’emplacement n’est plus disponible",
    "BookingValidationErrorModal.modal_title": "Titre",
    "BookingValidationErrorModal.service_button": "Voir les services",
    "BookingValidationErrorModal.service_unavailable_description": "Ce service n’est plus disponible. Veuillez vérifier si d’autres services répondent à vos besoins ou nous contacter au {phone}.",
    "BookingValidationErrorModal.service_unavailable_title": "Le service n’est plus disponible",
    "BookingValidationErrorModal.times_button": "Trouver une heure",
    "BookingValidationErrorModal.times_unavailable_description": "{time} n'est plus disponible le {date}. Vous devrez choisir un autre moment avant de pouvoir prendre rendez-vous.",
    "BookingValidationErrorModal.times_unavailable_title": "L’heure n’est plus disponible",
    "CancelModal.additional_details": "Détails supplémentaires",
    "CancelModal.appointment_date": "{date} à {time}",
    "CancelModal.cancel_appointment": "Annuler le rendez-vous",
    "CancelModal.keep": "Garder",
    "CancelModal.message": "L'annulation de votre rendez-vous prévu le {date} est irréversible.",
    "CancelModal.title": "Annuler le rendez-vous",
    "CancelModal.why_cancel_appointment": "Pourquoi ce rendez-vous est-il annulé ?",
    "ChangesToYourAppointment.cancellation_no_longer_available": "Ce rendez-vous ne peut plus être annulé.",
    "ChangesToYourAppointment.cancellation_policy_header": "Politique d'annulation",
    "ChangesToYourAppointment.cannot_cancel": "Ce rendez-vous ne peut pas être annulé.",
    "ChangesToYourAppointment.cannot_reschedule": "Ce rendez-vous ne peut pas être reporté.",
    "ChangesToYourAppointment.loading": "Chargement...",
    "ChangesToYourAppointment.meeting_method_subtitle": "Si vous souhaitez modifier la façon dont vous souhaitez assister à votre rendez-vous, veuillez changer le type de rendez-vous.",
    "ChangesToYourAppointment.meeting_method_subtitle_limited": "Si vous souhaitez modifier la façon dont vous souhaitez assister à votre rendez-vous, vous pouvez le faire jusqu'à {duration} avant l'heure prévue.",
    "ChangesToYourAppointment.meeting_method_subtitle_locked": "La méthode de rendez-vous ne peut pas être modifiée",
    "ChangesToYourAppointment.no_meeting_method_options": "Aucune autre option disponible",
    "ChangesToYourAppointment.reschedule_appointment": "Reporter le rendez-vous",
    "ChangesToYourAppointment.reschedule_header": "Reporter",
    "ChangesToYourAppointment.reschedule_no_longer_available": "Ce rendez-vous ne peut plus être reporté.",
    "ChangesToYourAppointment.reschedule_policy_details": "Si vous devez reprogrammer votre rendez-vous, vous pouvez le faire jusqu'à {duration} avant l'heure prévue.",
    "ChangesToYourAppointment.reschedule_policy_details_no_limit": "Si vous devez reprogrammer votre rendez-vous, vous pouvez le faire avant l'heure prévue.",
    "ChangesToYourAppointment.title": "Changements à votre rendez-vous",
    "ChangesToYourAppointment.update_meeting_method": "Changer le type de rendez-vous",
    "ChangesToYourAppointment.update_meeting_method_not_available": "Ce mode de rendez-vous ne peut plus être modifié.",
    "CheckIn.check_in": "Enregistrement",
    "CheckIn.check_in.aria_label": "Enregistrez-vous dans votre rendez-vous",
    "CheckIn.confirmation.subtitle": "Nous serons bientôt avec vous.",
    "CheckIn.confirmation.subtitle_notifiable": "Nous serons bientôt avec vous. Nous vous avertirons quand ce sera votre tour.",
    "CheckIn.confirmation.title": "Vous êtes enregistré!",
    "CheckIn.email": "Adresse courriel",
    "CheckIn.fetch_failed": "Nous sommes désolés, mais une erreur s'est produite lors de la recherche de votre rendez-vous. Veuillez actualiser la page et réessayer.",
    "CheckIn.join_line": "REJOIGNEZ LA LIGNE",
    "CheckIn.join_line.aria_label": "Cliquez ici pour rejoindre la file d'attente pour être vu au lieu de prendre rendez-vous",
    "CheckIn.join_line.title": "Vous n'avez pas de rendez-vous ?",
    "CheckIn.landing_page.title": "Consulter votre rendez-vous",
    "CheckIn.next": "Suivant",
    "CheckIn.next.aria_label": "Consulter votre rendez-vous",
    "CheckIn.not_found.email": "Nous sommes désolés, mais nous n'avons pas trouvé de rendez-vous à venir correspondant à l'adresse e-mail que vous avez saisi. Veuillez noter que les rendez-vous ne peuvent être enregistrés que dans les 30 minutes précédant ou suivant l'heure de début prévue.",
    "CheckIn.not_found.phone": "Nous sommes désolés, mais nous n'avons pas trouvé de rendez-vous à venir correspondant au numéro de téléphone que vous avez saisi. Veuillez noter que les rendez-vous ne peuvent être enregistrés que dans les 30 minutes précédant ou suivant l'heure de début prévue.",
    "CheckIn.phone": "Numéro de téléphone",
    "CheckIn.preference": "Utiliser votre {preference} pour rechercher votre rendez-vous",
    "CheckIn.subtitle": "Utilisez votre adresse e-mail ou votre numéro de téléphone pour rechercher votre rendez-vous",
    "CheckIn.subtitle_email_only": "Utilisez votre adresse e-mail pour consulter votre rendez-vous",
    "CheckIn.summary.fetch_failed": "Nous sommes désolés, mais une erreur s'est produite lors de l'enregistrement de votre rendez-vous. Veuillez actualiser la page et réessayer.",
    "CheckIn.summary.sms_updates": "Recevoir des mises à jour par SMS",
    "CheckIn.summary.sms_updates.dialog": "Recevoir des mises à jour par SMS ?",
    "CheckIn.summary.sms_updates.dialog.accept": "Oui, envoyez-moi des mises à jour par SMS",
    "CheckIn.summary.sms_updates.dialog.deny": "Non, enregistrez-vous sans recevoir de mises à jour",
    "CheckIn.summary.sms_updates.message": "Nous vous enverrons des mises à jour sur le moment où ce sera votre tour de {phone}",
    "CheckIn.summary.subtitle": "Confirmez les détails de votre rendez-vous ci-dessous et enregistrez-vous",
    "CheckIn.summary.title": "Confirmer et enregistrer",
    "CheckIn.title": "Consulter votre rendez-vous",
    "CircularProgress.loading": "Chargement",
    "Common.appointment_details": "Détails du rendez-vous",
    "CookieNotice.accept": "J'accepte",
    "CookieNotice.closed": "Clause de non-responsabilité relative aux cookies fermée.",
    "CookieNotice.ignore": "Ignorer",
    "CookieNotice.learn_more": "En savoir plus",
    "CookieNotice.message": "Nous utilisons des cookies pour vous garantir la meilleure expérience possible sur notre site.",
    "CookieNotice.title": "Avis de non-responsabilité concernant les cookies",
    "CountrySelectInput.label": "Pays",
    "CountrySelectInput.most_popular": "Le plus populaire",
    "CountryTimezoneModal.description": "Toutes les heures seront affichées selon le fuseau horaire sélectionné ci-dessous.",
    "CountryTimezoneModal.title": "Sélectionnez le fuseau horaire",
    "DateTime.locale_badge_label": "{name} parle {language}",
    "DateTime.locale_badge_tooltip": "Parle {language}",
    "DateTime.mobile_header": "Quand voudriez-vous prendre rendez-vous?",
    "DateTime.no_language_preference": " (pas de {locale})",
    "DateTime.no_times_available_on_date": "Aucun horaire disponible",
    "DateTime.select_time": "Sélectionnez l'heure",
    "DateTime.select_user": "Sélectionnez un membre du personnel",
    "DateTime.showing_times_for": "Affichage des heures pour",
    "DateTime.times_available_for_people_speaking": "Heures disponibles pour {locale} le {date}",
    "DateTime.times_available_on_date": "Heures disponibles le {date}",
    "DateTime.timezone_header": "Les heures sont affichées dans {timezone}",
    "DateTime.user_mobile_header": "Quand souhaitez-vous rencontrer {name} ?",
    "DateTime.user_schedule": "Sélectionnez l'heure du rendez-vous avec {name}",
    "DateTime.who_speaks": " qui parle {locale}",
    "Dates.all_day_date": "{date}, toute la journée",
    "Dates.all_day_today": "Aujourd'hui, toute la journée",
    "Dates.plural.day": "{count, plural, one {jour} other {jours}}",
    "Dates.plural.month": "{count, plural, one {mois} other {mois}}",
    "Dates.plural.week": "{count, plural, one {semaine} other {semaines}}",
    "Dates.plural.year": "{count, plural, one { année } other { années }}",
    "Dates.range_today": "Aujourd'hui, {start} - {end}",
    "Dates.range_today_with_timezone": "Aujourd'hui, {start} - {end} {timezone}",
    "Dates.today_with_timezone": "Aujourd'hui, {date} {timezone}",
    "DesktopUsers.locale_badge_tooltip": "Parle {language}",
    "Details.form_header": "Entrez vos informations",
    "DetailsForm.add": "Ajouter",
    "DetailsForm.add_attendee": "+ Ajouter un participant",
    "DetailsForm.add_attendee_title": "Ajouter un participant",
    "DetailsForm.additional_attendees": "Participants supplémentaires",
    "DetailsForm.additional_attendees_limit": "(max {limit} )",
    "DetailsForm.address": "Adresse",
    "DetailsForm.agree_tos": "En prenant rendez-vous, vous acceptez les {terms} et l’{privacy} de Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "En prenant rendez-vous, vous acceptez les {terms} et l’{privacy} de Coconut Software ainsi que l’{vendorPrivacy} de {vendor}.",
    "DetailsForm.agree_tos_with_tos": "En prenant rendez-vous, vous acceptez les {terms} et l’{privacy} de Coconut Software ainsi que les {vendorTerms} de {vendor}.",
    "DetailsForm.agree_tos_with_vendor": "En prenant rendez-vous, vous acceptez les {terms} et l’{privacy} de Coconut Software ainsi que les {vendorTerms} et l’{vendorPrivacy} de {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "En réservant un rendez-vous, vous acceptez les {vendorTerms} et l’{vendorPrivacy} de {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "En réservant un rendez-vous, vous acceptez l’{vendorPrivacy} de {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "En réservant un rendez-vous, vous acceptez les {vendorTerms} de {vendor}.",
    "DetailsForm.appointment_policy_action": "prise de rendez-vous",
    "DetailsForm.cell_phone": "Téléphone cellulaire",
    "DetailsForm.cell_phone_context": "Nous vous appellerons à ce numéro",
    "DetailsForm.city": "Ville",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/politique-de-confidentialite\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/conditions-utilisation\/",
    "DetailsForm.country": "Pays",
    "DetailsForm.duplicate_attendee": "Un participant avec ce nom et cette adresse courriel existe déjà",
    "DetailsForm.edit": "Enregistrer",
    "DetailsForm.edit_attendee_title": "Modifier le participant",
    "DetailsForm.email": "Courriel",
    "DetailsForm.explicit_compliance_agreement_label": "J'accepte",
    "DetailsForm.first_name": "Prénom",
    "DetailsForm.home_phone": "Numéro de téléphone fixe",
    "DetailsForm.last_name": "Nom de famille",
    "DetailsForm.notes": "Détails du rendez-vous",
    "DetailsForm.postal_code": "Code postal \/ code ZIP",
    "DetailsForm.primary_phone_context": "Nous vous appellerons à ce numéro",
    "DetailsForm.privacy": "avis de confidentialité",
    "DetailsForm.province": "Province",
    "DetailsForm.receive_sms": "Recevoir des notifications de rendez-vous par texto",
    "DetailsForm.receive_sms_required": "Nous utiliserons ces informations pour vous envoyer des mises à jour importantes sur votre rendez-vous",
    "DetailsForm.select_country": "Veuillez sélectionner un pays",
    "DetailsForm.select_province": "Veuillez sélectionner une province",
    "DetailsForm.select_state": "Veuillez sélectionner un État",
    "DetailsForm.sms_error_title": "Revenez pour recevoir des SMS.",
    "DetailsForm.state": "État",
    "DetailsForm.submit": "Continuer",
    "DetailsForm.terms_of_use": "conditions d'utilisation",
    "DetailsForm.walkin_policy_action": "rejoindre la ligne",
    "DetailsForm.work_phone": "Numéro de téléphone professionnel",
    "Dialog.clear": "Effacer",
    "Dialog.confirm": "Confirmer",
    "Dropzone.delete": "Effacer",
    "Dropzone.instructions": "Faites glisser les fichiers ici ou <span> parcourez pour télécharger <\/span>",
    "Duration.days": "{duration, plural, one {# jour } other {# jours }}",
    "Duration.hours": "{duration, plural, one {# heure } other {# heures }}",
    "Duration.minutes": "{duration, plural, one {# minute} other {# minutes}}",
    "Duration.weeks": "{duration, plural, one {# semaine } other {# semaines }}",
    "EmptyState.contact_vendor": "Veuillez réessayer ou nous contacter",
    "FastRoute.book_anyway": "Prenez un rendez-vous ",
    "FastRoute.skip_the_wait": "Sautez l'attente",
    "FastRoute.title": "Aucun rendez-vous nécessaire",
    "Feedback.already_submitted": "Commentaires déjà soumis, merci !",
    "Feedback.appointment_review_subtitle": "Veuillez nous dire comment vous avez ressenti votre rendez-vous.",
    "Feedback.appointment_review_title": "Rétroaction de rendez-vous",
    "Feedback.close": "Fermer la section des commentaires",
    "Feedback.error": "Veuillez fournir une note avant de soumettre",
    "Feedback.experience": "Votre expérience",
    "Feedback.experience_error_message": "La rétroaction est un champ obligatoire. Veuillez vous assurer que la longueur est supérieure à 144 caractères",
    "Feedback.experience_required": "Votre expérience (obligatoire)",
    "Feedback.google_description": "Nous apprécions vraiment vos commentaires et nous pensons que nos autres clients en bénéficieront. Si vous avez quelques instants pour leur faire part de votre expérience avec nous via un avis Google, cliquez sur le bouton ci-dessous et nous vous redirigerons.",
    "Feedback.google_submit": "Envoyer un avis Google",
    "Feedback.neutral": "Neutre",
    "Feedback.new_title": "Retour d'information",
    "Feedback.rating": "Note (obligatoire)",
    "Feedback.response_prompt": "Dites-nous comment votre expérience aurait pu être meilleure",
    "Feedback.send": "Envoyez des commentaires",
    "Feedback.sending": "Envoi de commentaires",
    "Feedback.staff_review_subtitle": "S'il vous plaît dites-nous comment notre membre du personnel a fait aujourd'hui.",
    "Feedback.staff_review_title": "Commentaires du personnel",
    "Feedback.subtitle": "Vos commentaires sont anonymes. Nous utiliserons ces informations pour améliorer l'expérience de réservation.",
    "Feedback.success": "Commentaires envoyés avec succès, merci!",
    "Feedback.thank_you": "Merci pour vos commentaires!",
    "Feedback.title": "Comment s'est passée votre expérience lors de la réservation de ce rendez-vous?",
    "Feedback.very_dissatisfied": "Très insatisfait",
    "Feedback.very_satisfied": "Très satisfait",
    "FetchSlots.error": "Quelque chose s'est mal passé, veuillez réessayer ultérieurement",
    "FindAvailableDate.earliest_available": "Le plus tôt disponible",
    "FindAvailableDate.finding_first_time": "Nous recherchons la première disponibilité",
    "FindAvailableDate.finding_next_time": "Nous recherchons la prochaine disponibilité",
    "FindAvailableDate.finding_time": "Trouver un horaire disponible",
    "FindAvailableDate.first_available_date": "Le plus tôt possible",
    "FindAvailableDate.next_available": "Prochaine disponibilité",
    "FindAvailableDate.next_available_date": "Date suivante",
    "FindAvailableDate.tooltip_error": "Erreur lors de la récupération de la date.",
    "FindAvailableDate.tooltip_fetching": "Date de récupération...",
    "Form.field_error.cell_phone_characters": "Veuillez saisir un numéro de téléphone portable valide.",
    "Form.field_error.cell_phone_length": "Veuillez entrer un numéro de téléphone portable valide comprenant entre 9 et 20 caractères.",
    "Form.field_error.email_structure": "S'il vous plaît, mettez une adresse email valide.",
    "Form.field_error_max_length": "Ce champ ne peut pas dépasser {max} caractères.",
    "Form.field_error_min_length": "Ce champ ne peut pas contenir moins de {min} caractères.",
    "Form.field_required": "Ce champ est obligatoire.",
    "Form.optional": "(facultatif)",
    "GenericError.subtitle": "Nous ne pouvons pas trouver la page que vous recherchez.",
    "GenericError.title": "Oups, quelque chose s'est mal passé",
    "Input.additional_input": "Message supplémentaire",
    "Invitations.verification_failed": "L’adresse courriel que vous avez fournie ne correspond pas à celle au dossier.",
    "Label.phone_number": "Numéro de téléphone",
    "LandingChoices.book_appointment_subtitle": "Sélectionnez une date et une heure spécifique pour voir quelqu'un",
    "LandingChoices.book_appointment_title": "Prendre un rendez-vous",
    "LandingChoices.callback_subtitle": "Voir quelqu'un aujourd'hui dès que possible",
    "LandingChoices.callback_title": "Rejoindre la ligne",
    "LandingChoices.check_in_subtitle": "Si vous êtes ici pour un rendez-vous pré-réservé",
    "LandingChoices.check_in_title": "Enregistrement",
    "LandingChoices.choose_another_location_subtitle": "Cliquez ici pour rechercher un autre emplacement",
    "LandingChoices.prefill_title": "Bienvenue {name} ! Qu'aimeriez-vous {space} faire ?",
    "LandingChoices.prefill_title.service": "Bienvenue {name}! Comment souhaitez-vous réserver votre rendez-vous {service}?",
    "LandingChoices.private_location": "Cet emplacement est privé",
    "LandingChoices.title": "Que souhaitez-vous faire?",
    "LandingChoices.title.service": "Comment souhaitez-vous réserver votre rendez-vous {service}?",
    "LandingChoices.virtual_location": "Emplacement virtuel",
    "LandingChoices.welcome_to": "Bienvenue à",
    "Languages.chinese": "chinois",
    "Languages.english": "anglais",
    "Languages.french": "français",
    "Languages.korean": "coréen",
    "Languages.language": "Langue",
    "Languages.polish": "polonais",
    "Languages.portuguese": "portugais",
    "Languages.russian": "russe",
    "Languages.spanish": "espagnol",
    "LobbyBanner.button": "Rejoignez la ligne",
    "LobbyBanner.button_description": "Cliquez ici pour rejoindre la file d'attente pour être vu au lieu de prendre rendez-vous",
    "LobbyBanner.phone_button": "Demander un rappel",
    "LobbyBanner.phone_button_description": "Cliquez ici pour réserver un rappel téléphonique au lieu de prendre un rendez-vous",
    "LobbyBanner.title": "Aucun rendez-vous nécessaire!",
    "LobbyBanner.wait_time": "Temps d'attente estimé: {time} minutes",
    "Location.previously_booked": "Précédemment réservé",
    "Location.search_area": "Rechercher dans cette zone",
    "Location.select_location": "Sélectionnez un emplacement",
    "Location.select_location_prefill": "{name}, sélectionnez un emplacement",
    "LocationDetails.continue": "Continuer",
    "LocationDetails.location_details": "Détails de l'emplacement",
    "LocationDetails.see_all_locations": "Voir tous les emplacements",
    "LocationDetails.select_location": "Sélectionnez cet emplacement",
    "LocationDirectionsButton.text": "Itinéraire",
    "LocationHours.closed": "Fermé",
    "LocationHours.title": "Heures d’ouverture",
    "LocationIdentifier.empty_error": "Veuillez entrer le numéro d’emplacement",
    "LocationIdentifier.help_text": "Commencez par entrer le numéro de l'emplacement que vous voyez sur le panneau",
    "LocationIdentifier.location_number": "Numéro d'emplacement",
    "LocationIdentifier.not_found_error": "Cela ne correspond à aucun de nos emplacements",
    "LocationIdentifier.title": "Bienvenue!",
    "LocationList.empty_state_message": "Aucun résultat trouvé",
    "LocationList.empty_state_secondary": "Essayez d'élargir votre recherche",
    "LocationList.finding_locations": "Nous trouvons des emplacements près de chez vous",
    "LocationList.near_me": "Emplacements à proximité",
    "LocationList.no_locations": "Aucun emplacement trouvé",
    "LocationList.title": "Sélectionnez un emplacement",
    "LocationList.toggle_list": "Afficher la liste",
    "LocationList.toggle_map": "Afficher la carte",
    "LocationPhoneCallButton.text": "Appeler {number}",
    "LocationStepTitle.preferred_location": "Quel est votre emplacement préféré?",
    "LocationStepTitle.preferred_location_helper": "Nous vous mettrons en relation avec le personnel de l'endroit que vous choisissez.",
    "LoginWithGoogle.showing_times_that_match": "Affichage des horaires correspondant à vos disponibilités",
    "LoginWithGoogle.sign_in": "Connectez-vous avec Google",
    "LoginWithGoogle.sign_in_details": "pour afficher les heures qui correspondent à vos disponibilités",
    "Manage.appointment_details": "Détails du rendez-vous",
    "Manage.book_another": "Prendre un autre rendez-vous",
    "Manage.can_never_cancel": "La gestion en ligne n'est pas disponible. Veuillez nous contacter pour apporter des modifications.",
    "Manage.cancel": "Annuler le rendez-vous",
    "Manage.cancellation_policy": "Politique d'annulation",
    "Manage.cancellation_policy_details": "Si vous ne pouvez pas assister à votre rendez-vous, veuillez annuler jusqu'à {duration} avant l'heure prévue.",
    "Manage.cancellation_policy_details_no_limit": "Si vous ne pouvez pas assister à votre rendez-vous, veuillez annuler avant l'heure prévue.",
    "Manage.cancelled": "Le rendez-vous est annulé",
    "Manage.cancelled_successfully": "Le rendez-vous a été annulé",
    "Manage.cannot_cancel_anymore": "La gestion en ligne n'est pas disponible pour le moment. Veuillez nous contacter pour apporter des modifications.",
    "Manage.confirmation": "Confirmation",
    "Manage.confirmation_email": "Nous vous avons envoyé un courriel avec les détails de votre rendez-vous, ainsi que toute information supplémentaire relative à votre rendez-vous.",
    "Manage.details": "Détails",
    "Manage.in_the_past": "Le rendez-vous est dans le passé",
    "Manage.reschedule": "Reporter",
    "Manage.restart": "Redémarrer depuis la page d'accueil",
    "Manage.successfully_booked": "Le rendez-vous a été pris avec succès",
    "Manage.updated_successfully": "Le rendez-vous a été mis à jour",
    "ManageAppointmentDetails.details_below": "Les détails de votre rendez-vous sont ci-dessous. Nous vous en avons envoyé une copie par courriel.",
    "ManageAppointmentDetails.done_title": "Terminé!",
    "ManageAppointmentDetails.successfully_booked": "Votre rendez-vous est réservé.",
    "ManageAppointmentDetails.successfully_rescheduled": "Votre rendez-vous a été reporté.",
    "ManageAppointmentDetails.your_appointment": "Votre rendez-vous",
    "MeetingMethodForm.appointment_date": "{date} à {time}",
    "MeetingMethodForm.keep": "Garder",
    "MeetingMethodForm.message.in_person": "Mettez à jour la méthode de réunion pour le rendez-vous qui est prévu le {date} pour vous rencontrer en personne.",
    "MeetingMethodForm.message.off_site": "Mettez à jour la méthode de réunion pour le rendez-vous prévu le {date} pour vous rencontrer où vous le souhaitez.",
    "MeetingMethodForm.message.phone_call": "Mettez à jour la méthode de réunion pour le rendez-vous planifié le {date} sous forme d'appel téléphonique.",
    "MeetingMethodForm.message.video_call": "Mettez à jour la méthode de réunion pour le rendez-vous planifié le {date} en tant qu'appel vidéo.",
    "MeetingMethodForm.new_method_label": "Comment allez-vous assister au rendez-vous?",
    "MeetingMethodForm.title": "Changer le type de rendez-vous",
    "MeetingMethodForm.update_method": "Changer le type de rendez-vous",
    "MeetingMethodInfoBanner.only_phone_available": "Ce rendez-vous peut seulement se faire par appel téléphonique",
    "MeetingMethodInfoBanner.only_video_available": "Ce rendez-vous peut seulement se faire par appel vidéo",
    "MeetingMethods.at_location_primary": "En personne",
    "MeetingMethods.at_location_secondary": "Choisissez l'un de nos emplacements",
    "MeetingMethods.header": "Comment allez-vous assister au rendez-vous?",
    "MeetingMethods.off_site_primary": "Hors site",
    "MeetingMethods.off_site_secondary": "Nous vous rencontrerons",
    "MeetingMethods.phone_call_primary": "Appel Téléphonique",
    "MeetingMethods.phone_call_secondary": "Nous vous appellerons",
    "MeetingMethods.video_call_primary": "Appel vidéo",
    "MeetingMethods.video_call_secondary": "Nous vous enverrons un lien",
    "MonthlyDatePicker.next_month": "Mois prochain",
    "MonthlyDatePicker.previous_month": "Mois précédent",
    "Navigation.aria_label.back": "Retour à l'étape précédente",
    "Navigation.aria_label.call": "Emplacement de l'appel",
    "Navigation.aria_label.card": "Sélectionnez {navItem}",
    "Navigation.aria_label.date": "Sélectionnez {date}",
    "Navigation.aria_label.directions": "Voir l'itinéraire",
    "Navigation.aria_label.language": "{language} personnel parlant",
    "Navigation.aria_label.language_specific": "un personnel spécifique",
    "NoLocationPreferenceButton.skip": "Sauter",
    "NoLocationPreferenceButton.title": "Je n'ai pas de préférence",
    "NoTimesAvailable.no_times_in_month": "Pas d’heure disponible en {month}",
    "NoTimesAvailable.no_times_in_week": "Pas d’heure disponible cette semaine",
    "NoTimesAvailable.view_next_month": "Voir le mois prochain",
    "NoTimesAvailable.view_next_week": "Voir la semaine prochaine",
    "NotAvailable.subtitle": "Nous ne prenons actuellement pas de rendez-vous",
    "NotAvailable.title": "Indisponible",
    "OtherLocationsTimeChunks.available_times_title": "Heures disponibles à d'autres endroits",
    "OtherLocationsTimeChunks.no_available_times": "Pas d'horaires disponibles dans d'autres endroits",
    "OtherLocationsTimeChunks.view_available_times": "Voir les heures disponibles à d'autres endroits",
    "OtherLocationsTimeChunks.view_more": "Voir plus",
    "Pages.country": "Pays",
    "Pages.staff_preference": "Préférence du personnel",
    "Pages.supported_language_preference": "Langues",
    "Pages.timezones": "Fuseaux horaires",
    "PositionErrorAlert.denied": "Nous n'avons pas pu obtenir votre position car nous n'avons pas votre autorisation. Vérifiez les paramètres de votre navigateur pour ce site.",
    "PositionErrorAlert.retry": "Recommencez",
    "PositionErrorAlert.unable": "Nous n'avons pas pu obtenir votre position.",
    "RadioButton": "Bouton radio",
    "Reschedule.appointment_details": "Détails du rendez-vous",
    "Reschedule.confirm_time": "Confirmer l’heure",
    "Reschedule.error": "Le rendez-vous ne peut être reporté",
    "Reschedule.label": "Confirmation du report de rendez-vous",
    "Reschedule.reschedule": "Reporter",
    "Security.continue": "Continuer",
    "Security.email_verification": "Vérification de l’adresse courriel",
    "Security.please_verify": "Veuillez vérifier votre adresse courriel avant de prendre rendez-vous.",
    "SelectInput.default": "Sélectionner",
    "Service.all_categories_title": "Toutes Catégories",
    "Service.category_title": "Catégories",
    "Service.five_minutes": "< 5 minutes",
    "Service.hours": "{hours, plural, one {# heure } other {# heures }}",
    "Service.minutes": "{minutes, plural, one {# minute } other {# minutes }}",
    "Service.mobile_header": "Dans quel domaine avez-vous besoin d'aide?",
    "Service.no_services": "Aucun service n’a été trouvé",
    "Service.read_less": "Moins",
    "Service.read_more": "Plus",
    "Service.select_title": "Sélectionnez un service",
    "Service.select_title_prefill": "{name},{break}sélectionnez un service",
    "Service.service_count": "{number, plural, one {# service} other {# services}}",
    "Service.uncategorized_title": "Autre",
    "ServiceHeader.group": "Groupe",
    "ServiceInstructions.title": "Consignes",
    "ShortcutExpired.message": "Ce lien a expiré",
    "Sidebar.footer": "Rendu possible par",
    "Sidebar.title": "Prendre rendez-vous",
    "Sidebar.title_check_in": "Enregistrement",
    "Sidebar.title_join_the_line": "Rejoignez la ligne",
    "SpecificUserLanguagePreferences.title": "Langue de préférence",
    "StepCounter.location": "{location}",
    "StepCounter.location_address": "{address}",
    "StepCounter.location_name": "{name}",
    "StepCounter.title": "Étape {current} de {end}",
    "Steps.check_in": "Enregistrement",
    "Steps.check_in_description": "<p> Enregistrez-vous pour votre rendez-vous. <\/p> <p> Nous vous aviserons lorsqu'un membre du personnel sera prêt à vous servir. <\/p>",
    "Steps.date_time": "Date et heure",
    "Steps.date_time_no_wait_times": "Dès qu'un membre du personnel est prêt à vous servir",
    "Steps.date_time_with_wait_times": "Temps d'attente estimé : {time} minutes",
    "Steps.date_time_with_zero_wait_times": "Pas de temps d'attente pour le moment",
    "Steps.details": "Détails",
    "Steps.details_description": "<p> Ajoutez-vous pour être vu aujourd'hui. <\/p> <p> Nous vous aviserons de revenir lorsqu'un membre du personnel sera prêt à vous servir. <\/p>",
    "Steps.location": "Emplacement",
    "Steps.meeting_details": "Détails du rendez-vous",
    "Steps.meeting_methods": "Type de rendez-vous",
    "Steps.no_staff_warning": "En rejoignant la file, vous serez servi par le premier membre du personnel disponible. Il se peut que ce ne soit pas {name}, que vous aviez précédemment sélectionnée.",
    "Steps.service": "Service",
    "Steps.services": "Services",
    "Steps.staff": "Membre du personnel",
    "Steps.staff_preference": "Préférence du personnel",
    "Steps.summary": "Sommaire",
    "Steps.your_details": "Vos informations",
    "Summary.book_appointment": "Prendre rendez-vous",
    "Summary.header": "Presque fini! Revoir et prendre le rendez-vous",
    "Summary.join_the_line": "Rejoignez la ligne",
    "Summary.please_review": "Veuillez passer en revue",
    "SummaryExpansionPanel.title": "Sommaire",
    "SupportedLanguagePreferencePicker.anyone": "toute personne",
    "SupportedLanguagePreferencePicker.anyone_available": "toute personne disponible",
    "SupportedLanguagePreferencePicker.assigned_staff": "votre personnel assigné",
    "SupportedLanguagePreferencePicker.language_preference": "préférence de langue",
    "SupportedLanguagePreferencePicker.specific_language": "toute personne qui parle {language}",
    "Svg.alt_text.appointment": "Rendez-vous",
    "Svg.alt_text.back": "Arrière",
    "Svg.alt_text.back_chevron": "Arrière",
    "Svg.alt_text.checkbox_not_selected": "Case à cocher non sélectionnée",
    "Svg.alt_text.checkbox_selected": "Élément de case à cocher sélectionné",
    "Svg.alt_text.checkmark": "Coche",
    "Svg.alt_text.clock": "Horloge",
    "Svg.alt_text.coconut_logo": "Logo de Coconut Software",
    "Svg.alt_text.completed": "Complété",
    "Svg.alt_text.continue": "Continuer",
    "Svg.alt_text.country_selected": "Pays sélectionné",
    "Svg.alt_text.down_chevron": "Montre plus",
    "Svg.alt_text.drop_down_arrow": "Flèche déroulante",
    "Svg.alt_text.edit": "Éditer",
    "Svg.alt_text.edit_timezone": "Changer de fuseau horaire",
    "Svg.alt_text.expand": "Ouvrir",
    "Svg.alt_text.expanded": "Ouvert",
    "Svg.alt_text.forward_chevron": "Avancez",
    "Svg.alt_text.hide_location_details": "Masquer les détails de l'emplacement",
    "Svg.alt_text.in_person": "À l'emplacement",
    "Svg.alt_text.item_selected": "Article sélectionné",
    "Svg.alt_text.language": "Langue",
    "Svg.alt_text.location_pin": "Emplacement",
    "Svg.alt_text.near_me": "Proche de moi",
    "Svg.alt_text.next_month": "Le mois prochain",
    "Svg.alt_text.next_week": "La semaine prochaine",
    "Svg.alt_text.person": "Personne",
    "Svg.alt_text.phone": "Téléphone",
    "Svg.alt_text.previous_month": "Le mois précédent",
    "Svg.alt_text.previous_week": "Semaine précédente",
    "Svg.alt_text.search": "Chercher",
    "Svg.alt_text.select_category": "Choisir une catégorie",
    "Svg.alt_text.select_language": "Sélectionnez une langue",
    "Svg.alt_text.show_location_details": "Afficher les détails de l'emplacement",
    "Svg.alt_text.timezone": "Fuseau horaire",
    "Svg.alt_text.timezone_selected": "Fuseau horaire sélectionné",
    "Svg.alt_text.up_chevron": "Montrer moins",
    "Svg.alt_text.upload": "Télécharger",
    "Svg.alt_text.user_selected": "Utilisateur sélectionné",
    "Svg.alt_text.video": "Vidéo",
    "Svg.alt_text.walk_in": "Icône de service sans rendez-vous",
    "TimeChunk.no_spots": "Aucune place restante",
    "TimeChunk.spots_remaining": "{number, plural, one {# point restant} other {# points restants}}",
    "TimeChunk.unlimited_spots": "Nombre illimité de places",
    "TimeChunks.afternoon": "Après-midi",
    "TimeChunks.evening": "Soir",
    "TimeChunks.morning": "Matin",
    "TimeChunks.no_available_times": "Pas d’heure disponible",
    "TimeChunks.no_available_times_in_month": "Aucune disponibilité trouvée ce mois-ci.",
    "TimeChunks.no_available_times_in_range": "Aucune disponibilité trouvée dans une période de {range} {rangeUnit}.",
    "TimeChunks.no_available_times_in_range_month": "Aucune disponibilité trouvée sur une période {range} mois.",
    "TimeChunks.no_available_times_in_range_week": "Aucune disponibilité trouvée sur une période {range} semaines.",
    "TimeChunks.no_available_times_in_week": "Aucune disponibilité cette semaine. ",
    "TimeChunks.no_available_times_on": "Aucun rendez-vous disponible le {date}",
    "TimeChunks.select_another_day": "Sélectionnez un autre jour pour trouver des heures disponibles",
    "TimeChunks.select_another_month": "Essayez un autre mois.",
    "TimeChunks.select_another_range": "Sélectionnez un autre membre du personnel ou un autre emplacement.",
    "TimeChunks.select_another_week": "Choisissez une autre semaine.",
    "TimeChunks.select_user": "Sélectionnez un membre du personnel pour voir ses disponibilités",
    "TimezoneSelectInput.label": "Région du fuseau horaire",
    "TimezoneSelectInput.required": "Le fuseau horaire est requis",
    "TimezonesShownIn.label": "Affichage des heures dans",
    "TooLateToCancelModal.message": "Vous ne pouvez plus annuler ce rendez-vous en ligne.",
    "TooLateToCancelModal.title": "Trop tard pour annuler",
    "Ui.archive": "Archive",
    "Ui.back": "Retour",
    "Ui.back_to_previous": "Retour vers: {previous}",
    "Ui.cancel": "Annuler",
    "Ui.clear": "Effacer",
    "Ui.close": "Fermer",
    "Ui.confirm": "Confirmer",
    "Ui.continue": "Continuer",
    "Ui.current_selection": "Sélection actuelle",
    "Ui.details_about_name": "Détails sur {name}",
    "Ui.details_about_name_and_address": "Détails sur {name}, à {address}",
    "Ui.dismiss": "Rejeter",
    "Ui.do_not_continue": "Continuez",
    "Ui.hours_of_operation": "Heures d'ouverture",
    "Ui.near_me": "Proche de moi",
    "Ui.next": "Suivant",
    "Ui.ok": "D'accord",
    "Ui.search": "Chercher",
    "Ui.search.instructions": "Veuillez commencer à taper",
    "Ui.search.no_results_found": "Aucun résultat trouvé",
    "Ui.search.plain": "Rechercher",
    "Ui.select": "Sélectionner",
    "Ui.select_name": "Sélectionnez {name}",
    "Ui.selected": "Sélectionné",
    "Ui.sign_out": "Déconnexion",
    "Ui.type_here": "Écrivez ici...",
    "Ui.undo": "Annuler",
    "UserPreference.anyone": "Toute personne",
    "UserPreference.anyone_available": "Toute personne disponible",
    "UserPreference.assign_me_short_title": "{language}",
    "UserPreference.assign_me_subtitle": "Je souhaite prendre rendez-vous avec quelqu’un qui parle {language}",
    "UserPreference.assign_me_title": "Toute personne qui parle {language}",
    "UserPreference.assigned_title": "Votre personnel assigné",
    "UserPreference.header": "Souhaitez-vous prendre rendez-vous avec un membre du personnel en particulier?",
    "UserPreference.language_title": "{language}",
    "UserPreference.no_preference": "Aucune préférence",
    "UserPreference.random_subtitle": "Je n'ai pas de préférence",
    "UserPreference.random_title": "Toute personne disponible",
    "UserPreference.see_all_staff": "Voir tout le personnel",
    "UserPreference.specific_subtitle": "Je sais exactement qui je veux rencontrer",
    "UserPreference.specific_title": "Un membre du personnel en particulier",
    "Validator.address": "adresse",
    "Validator.cell_phone": "téléphone cellulaire",
    "Validator.city": "ville",
    "Validator.country": "pays",
    "Validator.email": "adresse courriel",
    "Validator.first_name": "prénom",
    "Validator.home_phone": "téléphone fixe",
    "Validator.last_name": "nom de famille",
    "Validator.meeting_method": "méthode de rencontre",
    "Validator.not_url": "Ne peut pas contenir d'URL.",
    "Validator.notes": "Remarques",
    "Validator.phone_code": "Code pays incorrect",
    "Validator.phone_country_code": "Veuillez fournir un code pays de 1 à 3 chiffres précédé de '+' et suivi de votre numéro de téléphone.",
    "Validator.phone_length": "Longueur de téléphone incorrecte",
    "Validator.phone_valid": "Veuillez fournir un numéro de téléphone valide.",
    "Validator.postal_code": "code postal",
    "Validator.province": "province",
    "Validator.state": "État",
    "Validator.work_phone": "numéro de téléphone professionnel",
    "Validator.zip_code": "code postal",
    "ViewOnMapButton.title": "Voir sur la carte",
    "WaitTime.loading": "Obtention du temps d’attente…",
    "WaitTime.no_walkins_currently": "Pas de rencontre sans rendez-vous pour le moment",
    "WaitTime.wait": "Temps d’attente pour les clients sans rendez-vous : {wait}",
    "WaitTime.wait_time": "Temps d’attente pour les clients sans rendez-vous : {waitTime}",
    "WeeklyDatePicker.next_week": "Semaine suivante",
    "WeeklyDatePicker.previous_week": "Semaine précédente",
    "WhatNow.homepage": "Accédez au site Web de {vendor}",
    "WhatNow.title": "Et maintenant?"
}